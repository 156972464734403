/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/whatwg-fetch@3.6.20/dist/fetch.umd.min.js
 * - /npm/promise-polyfill@8.3.0/dist/polyfill.min.js
 * - /npm/rellax@1.12.1/rellax.min.js
 * - /npm/canvas-confetti@1.9.3/dist/confetti.browser.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
